.social-menu {
    position: fixed;
    top: 10px;
    right: 20px;
    display: flex;
    gap: 30px;
  }
  
  .social-menu a {
    color: #333;
    font-size: 24px;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .social-menu a:hover {
    color: #007bff; /* Change to your preferred hover color */
  }