body {
  background-color: #fff;
}

.mainImgContainer {
  background-image: url('./opp_main_gm.webp'); /* Replace with the actual path to picGm */
  background-size: cover; /* Ensure the image covers the entire container */
  background-position: center; /* Center the image */
  width: 100%; /* Full width */
  height: 100vh; /* Full height of the viewport */
}

.grey {
  color: #2e2e2e;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
  font-size: 14px;
}

.App-link {
  color: #61dafb;
}

.spacer {
  width: 100%;
  height: 100px;
}

.square-bubble {
  width: auto; /* Adjust the width as needed */
  height: auto; /* Adjust the height as needed */
  background-color: rgba(255, 255, 255, 0.8); /* White background with opacity */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Shadow */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  border-radius: 10px; /* Optional: to make the corners rounded */
  position: absolute;
  bottom: 30px; /* 30px from the bottom */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
}

.tinyFont {
  font-size: 12px;
}

.story img {
  max-width: 100%;
  height: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
