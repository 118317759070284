  .hamburger-menu {
    position: relative;
    padding-left: 20px;
    padding-top: 20px;  
  }
  
  .menu-icon {
    width: 30px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
  }
  
  .menu-icon div {
    width: 100%;
    height: 4px;
    background-color: #333;
    transition: all 0.3s ease;
  }
  
  .menu-icon.open .bar1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  
  .menu-icon.open .bar2 {
    opacity: 0;
  }
  
  .menu-icon.open .bar3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
  
  .menu-content {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 55px;
    left: 20px;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .menu-content.open {
    display: flex;
  }
  
  .menu-content a {
    padding: 10px 20px;
    text-decoration: none;
    color: #333;
    border-bottom: 1px solid #ddd;
  }
  
  .menu-content a:hover {
    background-color: #f1f1f1;
  }