.collage-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px; /* Adjust the gap between images as needed */
    padding: 20px;
  }
  
  .collage-image {
    max-width: 200px; /* Adjust the max width as needed */
    max-height: 200px; /* Adjust the max height as needed */
    object-fit: cover;
    border-radius: 10px; /* Optional: to make the corners rounded */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: to add a shadow */
  }